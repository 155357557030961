import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { PieValueType } from '@mui/x-charts';

// type Props{

// }

type Props = {
    data: Array<PieValueType>,
    percentuale: boolean
}
export const valueFormatter = (item: { value: number }, percentuale: boolean) => {
  if (percentuale) {
    return `${item.value.toFixed(2)}%`
  } else {
    return `${item.value}`
  }
};

export default function GraficoTorta({ data, percentuale }: Props) {
  const datiGrafico = {
    data: data,
    valueFormatter: (item: { value: number }) => valueFormatter(item, percentuale),
  }

  return (
    <PieChart
      series={[
        {
          ...datiGrafico,
        },
      ]}
      width={400}
      height={200}
    />
  );
}

